import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Radio } from "components/radio";
import { Field, useFormikContext } from "formik";
import { useEffect } from "react";
import { formatCurrency } from "utils/currencyUtils";
import * as Yup from "yup";

export const getAmountFieldsSchema = ({ totalAmount }) =>
  Yup.object().shape({
    selectedPaymentAmount: Yup.string().oneOf(["full_amount", "partial_amount"]).required(),
    paymentIntent: Yup.object().shape({
      totalAmount: Yup.number().when("selectedPaymentAmount", {
        is: "partial_amount",
        then: () =>
          Yup.number()
            .min(1, "Partial amout must be at least 1$!")
            .max(totalAmount, "Partial amount must be less than full amount!")
            .required("Required"),
      }),
    }),
  });

export function AmountFields({ totalAmount }) {
  const { values, setFieldValue } = useFormikContext();

  // Reset total amount field when total amount changes, for example when a new payment is selected
  useEffect(() => {
    setFieldValue("paymentIntent.totalAmount", totalAmount);
  }, [setFieldValue, totalAmount]);

  const radioOnChange = (val) => {
    if (val === "full_amount") {
      setFieldValue("paymentIntent.totalAmount", totalAmount);
    }
  };

  const paymentAmountOptions = [
    {
      label: (
        <HStack>
          <Text>Full Balance {totalAmount ? `(${formatCurrency(totalAmount)})` : null}</Text>
        </HStack>
      ),
      value: "full_amount",
    },
    {
      label: (
        <HStack>
          <Text>Partial Payment</Text>
        </HStack>
      ),
      value: "partial_amount",
    },
  ];

  return (
    <VStack spacing={6} my={[6]}>
      <Field name="selectedPaymentAmount">
        {({ field, form }) => (
          <FormControl
            isInvalid={form.errors.selectedPaymentAmount && form.touched.selectedPaymentAmount}
          >
            <FormLabel>Payment Type</FormLabel>
            <Radio
              form={form}
              field={field}
              options={paymentAmountOptions}
              onChangeCallback={radioOnChange}
            />
          </FormControl>
        )}
      </Field>
      {values.selectedPaymentAmount === "partial_amount" && (
        <Field name="paymentIntent.totalAmount">
          {({ field, form }) => (
            <FormControl
              isInvalid={
                form.errors.paymentIntent?.totalAmount && form.touched.paymentIntent?.totalAmount
              }
            >
              <FormLabel>Partial Payment Amount</FormLabel>
              <NumberInput
                value={field.value}
                onChange={(val) => form.setFieldValue(field.name, val)}
                step={0.01}
                min={0}
                precision={2}
                max={totalAmount}
              >
                <NumberInputField {...field} placeholder="Enter amount..." />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>{form.errors.paymentIntent?.totalAmount}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      )}
    </VStack>
  );
}
